import React, {useState} from 'react';
import logo from '../assets/images/spencehub.png';
import {Button, Col, Container, Image, Navbar, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {getLoadingSpanSM} from "../util/common";
import axios from "axios";
import {API_ROUTES} from "../routes/routes";
import {storeLocation} from "../const/values";

const OptOut: React.FC = () => {
  const [processing, setProcessing] = useState(false);
  const [status, setStatus] = useState(0); //status codes returned by api.
  const [errorMsg, setErrorMsg] = useState('error');

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useUrlQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useUrlQuery();
  let email = query.get('email');
  let sid = query.get('sid');
  // console.log('TM: ' + sid);

  const getOperationFailedMsg = (error: string | null) => {
    return(
      <p className='text-black-50 font-weight-bold text-small'>
        <span className='text-danger'>{error ? error : 'Operation failed!'}</span> <br/>
        contact <span className='text-info'>info@softvessel.com</span> for further assistance.</p>
    )
  };

  const getProblemMessage = () => {
    if (!email) {
      return(
        <p className='text-black-50 font-weight-bold text-small'>Problem loading email. <br/>
        contact <span className='text-info'>info@softvessel.com</span> for further assistance.</p>
      )
    }

    getOperationFailedMsg(null);
  };

  const renderError = () => {
    return getOperationFailedMsg(errorMsg);
  };

  const renderFeedback = () => {
    return (
      <React.Fragment>
        <p className='text-center feedback text-success'>
          <i className='feather icon-check-circle'/> Unsubscribed.
        </p>
      </React.Fragment>
    );
  };

  const getForm = () => {
    return(
      <React.Fragment>
        <h4>Unsubscribe emails</h4>
        <p className='mt-4 text-info font-weight-bold'>{email}</p>
        <p className='unsub-description'>Yes, I want to stop receiving
          <span className='font-italic'> discounts, coupons, offers,</span> and
          promotional emails to this email address, from spencehub in the future.</p>

        <Row>
          <Col xs={6} sm={4} className='pr-0'>
            <Button variant={"info"} disabled={processing} onClick={handleSubmit}>
              {processing && getLoadingSpanSM()}
              Unsubscribe
            </Button>

          </Col>
          <Col xs={6} sm={6}>
            <a href='#' className='keep-in'>Keep me in.</a>
          </Col>
        </Row>

      </React.Fragment>
    )
  };


  const handleSubmit = () => {
    setProcessing(true);

    const deal = {
      email: email,
      sid: sid,
    };

    axios
      .post(API_ROUTES.OPTOUT, deal)
      .then((res: any) => {
        setProcessing(false);
        setStatus(200);

      }).catch((err: any) => {
      setProcessing(false);

      if (!err.response) {
        setStatus(500);
        setErrorMsg('Network Problem. Try again later.');
      }

      if (err.response &&
        (err.response.status === 400 || err.response.status === 403 || err.response.status === 500)) {
        setStatus(err.response.status);
        setErrorMsg(err.response.data.msg);
        return;
      }

      setStatus(400);
    });

  };

  return (
    <React.Fragment>
      <Navbar bg="light" expand="lg" className='unsub-nav'>
        <Navbar.Brand href="#home">
          <Image src={logo} className='menu-logo'/>
        </Navbar.Brand>
      </Navbar>

      <Container>
        <Row>
          <Col xs={12} md={{span: 6, offset: 0}} >
            <div className='optout-form'>
              {(status === 0 && (!email || !sid)) && getProblemMessage()}

              {(status === 0 && email && sid) && getForm()}

              {[400, 403, 500].indexOf(status) > -1 && renderError()}

              {status === 200 && renderFeedback()}

            </div>
          </Col>
        </Row>
      </Container>

    </React.Fragment>
  );
};

export default OptOut;