import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {getDateFormatted, getLoadingSpan} from "../../util/common";
import axios from "axios";
import {API_ROUTES} from "../../routes/routes";
import {Redirect} from 'react-router-dom';
import {storeLocation} from "../../const/values";
import UploadCountdown from "../common/UploadCountdown";

const FormView: React.FC = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');

  const [processing, setProcessing] = useState(false);
  const [validated, setValidated] = useState(false);

  const [errorMsg, setErrorMsg] = useState('error');
  const [status, setStatus] = useState(0); //status codes returned by api.

  const initCountDown = 7;
  const [countdown, setCountdown] = useState(initCountDown);

  const startCountdown = () => {
    if (countdown < 1) {
      return;
    }

    setCountdown(countdown - 1);
  };

  useEffect(() => {
    if (countdown === initCountDown) {
      return;
    }

    let interval = setInterval(() => {
      if (countdown < 1) {
        clearInterval(interval);
        return;
      }
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeTel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTel(event.target.value);
  };

  const isValidateInput = () => {
    if (name !== '' && email !== '' && tel !== '') {
      setValidated(true);
      return true;
    }

    setValidated(true);
    return false;
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    setValidated(false);
    if (!isValidateInput()) {
      return;
    }

    setProcessing(true);

    const deal = {
      name: name,
      email: email,
      tel: tel
    };

    axios
      .post(API_ROUTES.OPTIN, deal)
      .then((res: any) => {
        setProcessing(false);
        setStatus(200);
        // setCountdown(countdown - 1);
        startCountdown();

      }).catch((err: any) => {
      setProcessing(false);

      if (!err.response) {
        setStatus(500);
        setErrorMsg('Problem getting coupon. Try again later.');
      }

      if (err.response &&
        (err.response.status === 400 || err.response.status === 403 || err.response.status === 500)) {
        // console.log(err.response);

        setStatus(err.response.status);
        setErrorMsg(err.response.data.msg);
      }

    });

  };

  const handleEnterKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const renderForm = () => {
    return (
      <React.Fragment>
        <div className="form-label10 footer-text justify-content-center form-input mt-1">
          <div className="mx-md-5 mx-3">

            <label className='text-right position-absolute countdown-container mr-md-5 mr-3 pt-md-2 pt-1'>
              {getUploadCountdown()}
            </label>

            <Form onSubmit={handleFormSubmit} validated={validated}>
              <Form.Group controlId="validationCustomName">
                <Form.Label>NAME</Form.Label>
                <Form.Control type="text" required={true} placeholder="YOUR NAME"
                              className='text-capitalize'
                              onChange={handleChangeName}/>

                {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                <Form.Control.Feedback type="invalid">
                  Please provide your name
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustomEmail">
                <Form.Label>E-MAIL ADDRESS</Form.Label>
                <Form.Control type="email" required={true} placeholder="example@example.com"
                              onChange={handleChangeEmail}/>

                {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                <Form.Control.Feedback type="invalid">
                  Please provide valid email
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustomEmail">
                <Form.Label>CONTACT NUMBER</Form.Label>
                <Form.Control type="tel" required={true} maxLength={10} placeholder="0777123456"
                              onKeyPress={handleEnterKeyPress}
                              onChange={handleChangeTel}/>

                {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
                <Form.Control.Feedback type="invalid">
                  Please provide phone number
                </Form.Control.Feedback>
              </Form.Group>

            </Form>
            <p className='privacy'>
              We value your privacy. We do not share your information with any other party.
              We will not spam you.
            </p>
          </div>
        </div>
        <div className="send-now-btn mx-md-4 mx-3">
          <Button type="submit" onClick={handleSubmit} className='text-uppercase' disabled={processing}>
            {processing && getLoadingSpan()} SEND ME THE COUPON NOW
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const renderError = () => {
    return (
      <React.Fragment>
        <p className='text-danger feedback text-center'>{errorMsg}</p>
        <a href={storeLocation} className='view-store text-center'>
          View store
        </a>
      </React.Fragment>
    );
  };

  const sentToStore = () => {
    if (countdown === 0) {
      // console.log(countdown);
      window.location.href = storeLocation;
    }
  };

  const getUploadCountdown = () => {
    const today = new Date();
    // const endDate = getDateTime(booking.createdAt); //end date to upload passports
    const endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 10);
    endDate.setSeconds(endDate.getSeconds() + 3);

    if (endDate.getTime() < today.getTime()) {
      return (
        <label className='text-danger expired-upload'>
          {/*expired on {getDateFormatted(endDate, 'DD/MM/YYYY')}*/}
          expired!
        </label>
      );
    }

    return <UploadCountdown uploadDeadline={endDate} message=''/>;
  };

  const renderFeedback = () => {
    return (
      <React.Fragment>
        <p className='text-center feedback'>Coupon set to your email</p>
        <p className='text-center feedback-important'>
          Please check <span className='highlight'>"promotion"</span> tab in your email,<br/> if it's not in your inbox.
        </p>
        <a href={storeLocation} className='view-store text-center'>
          View store ({countdown})
        </a>
        {sentToStore()}
      </React.Fragment>
    );
  };

  return (
    <Container>
      <div className="form-bg">
        <div className="form-header">
          <h1 className='text-uppercase'>
            free coupon to take your business online.
            get it now before it expires.
          </h1>
        </div>

        {status === 0 && renderForm()}

        {[400, 403, 500].indexOf(status) > -1 && renderError()}

        {status === 200 && renderFeedback()}
      </div>

      <Row>
        <Col xs={12}>
          <a href={storeLocation} className='view-store-only text-center' target='_blank'>
            let me view demo first and get the coupon later
          </a>
        </Col>
      </Row>

    </Container>
  );
};

export default FormView;