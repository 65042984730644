import React, {useEffect, useState} from 'react';
import TopHeader from "./TopHeader/TopHeader";
import Homepage from "../layout/Homepage";
import {useParams, useLocation} from "react-router-dom";
import axios from "axios";
import {API_ROUTES} from "../routes/routes";

const Optin: React.FC = () => {
  const { title } = useParams();
  // console.log(title);
  const [status, setStatus] = useState(0); //status codes returned by api.
  const sentStatusKey = 'sidS';
  const sentStatus = 'sent';

  function useUrlQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const updateSentStatus = () => {
    localStorage.setItem(sentStatusKey, sentStatus);
  };

  const query = useUrlQuery();
  let sid = query.get('sid');
  // console.log(sid);

  useEffect(() => {
    // console.log(title);
    // console.log(sid);
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    // console.log(title);
    // console.log(sid);

    if (!sid) {
      return;
    }

    const status = await localStorage.getItem(sentStatusKey);
    if (status && status === sentStatus) {
      return;
    }

    const deal = {
      sc: sid,
      ac: 'opt',
    };

    axios
      .post(API_ROUTES.SID, deal)
      .then((res: any) => {
        setStatus(200);
        updateSentStatus();
      }).catch((err: any) => {

      if (!err.response) {
        setStatus(500);
        // setErrorMsg('Network Problem. Try again later.');
      }

      if (err.response &&
        (err.response.status === 400 || err.response.status === 403 || err.response.status === 500)) {
        setStatus(err.response.status);
        // setErrorMsg(err.response.data.msg);
        return;
      }

      setStatus(400);
    });

  };

  return (
    <React.Fragment>
      <TopHeader/>
      <Homepage/>
    </React.Fragment>
  );
};

export default Optin;