import React from 'react';
import {Col, Container, Image, Navbar, Row} from "react-bootstrap";
import shoppingGirl from '../../assets/images/cart.png';
import arrow from '../../assets/images/arrow.png';
import {storeLocation} from "../../const/values";
import logo from '../../assets/images/spencehub.png';


function GetYourDealSection() {
    return (
        <React.Fragment>
            <Container className="logo">
              <Image src={logo} className='menu-logo'/>
              {/*<h1>SpenceHub</h1>*/}
            </Container>
            {/*<div className="promo-img">*/}
            {/*    /!*<img src={shoppingGirl} alt={shoppingGirl}/>*!/*/}
            {/*</div>*/}
          <div className="video embed-responsive embed-responsive-16by9">
            <iframe title='spencehub' className="embed-responsive-item" src="https://www.youtube.com/embed/8b9mh9tJf-Q"
                    allowFullScreen/>
          </div>
            <div className="promo-text text-center">

                <p className='text-uppercase get-deal'>get your deal now</p>
              <p className='auto-redir'>
                You will be automatically redirected to demo after getting coupon.
              </p>
            </div>
            {/*<div className="arrow">*/}
            {/*    <img src={arrow} alt={arrow} />*/}
            {/*</div>*/}

          {/*<Row>*/}
          {/*  <Col xs={12}>*/}
          {/*    <a href={storeLocation} className='view-store-only text-center'>*/}
          {/*      let me view store first*/}
          {/*    </a>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
        </React.Fragment>
    )
}

export default GetYourDealSection;