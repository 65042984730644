import React from 'react';
import './App.scss';
import {Switch, Route, Router} from "react-router";
import Optin from "./components/Optin";
import OptOut from "./components/OptOut";
import {createBrowserHistory} from "history";

function App() {
  const hist = createBrowserHistory();

  return (
    <Router history={hist}>
      <Switch>
        <Route path='/optout' exact={true} component={OptOut}/>
        <Route path='/:title?' component={Optin}/>
      </Switch>
    </Router>

  );
}

export default App;
