import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

class TopHeader extends React.Component {
    render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col xs={12} sm={12} md={8} lg={8} className="top-header-main-bg header-text">
                        <div className="top-header-arrow-bg" />
                        <h1 className='text-uppercase'>
                            get a predictable monthly income by selling your products online
                        </h1>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="top-header-secondary-bg header-sub-text">
                        <h1 className='font-italic'>GET YOUR COUPON NOW</h1>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default TopHeader;