import {Spinner} from "react-bootstrap";
import React from "react";
import moment from "moment";

export const getLoadingSpan = () => {
  return <Spinner
    as="span"
    animation="grow"
    role="status"
    aria-hidden="true"
  />
};

export const getLoadingSpanSM = () => {
  return <Spinner
    as="span"
    size={"sm"}
    animation="grow"
    role="status"
    aria-hidden="true"
  />
};

export const getDateFormatted = (date: Date | null, format: string) => {
  // FIND formats @ https://momentjs.com/docs/#/displaying/
  if (date) {
    return moment(date).format(format);
  }
  return 'N/A';
};