import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import GetYourDealSection from "../components/GetYourDealSection/GetYourDealSection";
import FormView from "../components/FormView/FormView";

function Homepage() {
    return(
        <Container fluid={true} className="homepage-bg pl-0">
            <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <GetYourDealSection/>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <FormView/>
                </Col>
            </Row>
        </Container>
    )
}

export default Homepage;