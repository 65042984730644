import React from 'react';

const API_BASE = process.env.REACT_APP_API_URI;

export const API_ROUTES = {
  BASE: API_BASE,
  OPTIN: API_BASE + "/optin",
  OPTOUT: API_BASE + "/optout",
  SID: API_BASE + "/sid",
};
